$reservations-header-color: #4C346B;
$primary-color: #6244BB;
$primary: #6244BB;
$secondary-color-active: #F6F2F7;
$blurple: $primary;
$purple-deep: #221551;
$violet: #440099;
$purple-medium: #C1A7E2;
$purple-light: #DACAED;
$aqua: #00C7B1;
$blue-light: #5BC2E7;
$red: #EF3340;
$orange: #FF7500;
$orange-light: #FF9800;
$aqua-light: #88DBDF;
$dark: #2D2926;
$white: #fff;
$gray-dark: #B1B3B3;
$gray-medium: #D8D9D9;
$gray-light: #EFF0F0;
$darker: #0D0C0C;

$violet-dark: $dark;
$breakpoint-xs: 0;
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;
$font-family-sans-serif: 'f37_moonregular', sans-serif;
$font-bold: 'f37_moondemi';
$font-bolder: 'f37_moonextra_bold';
$font-boldest: 'f37_moonblack';
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
.app-modal {
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: rgb(104, 76, 141);
    z-index: 1000;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .icon-container {
        height: 120px;
        width: 120px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        .loading-circle {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            .circle {
                border: solid 4px rgba(255, 255, 255, 0.5);
                animation: grow 2000ms infinite;
                border-radius: 50%;
            }
        }
    }

}

.app-modal.in-component-modal {
    position: absolute;
    background: rgba(104, 76, 141, 0.85);
}

@keyframes grow {
    0% {
        height: 35px;
        width: 35px;
        border-width: 4px;
    }
    40% {
        height: 110px;
        width: 110px;
        border-width: 2px;
    }
    100% {
        height: 35px;
        width: 35px;
        border-width: 4px;
    }
}
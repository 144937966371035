@import '../App/Vars.scss';

.club-list {
    height: 100%;
    flex-direction: column;
    overflow-y: hidden;

    .club-info-container {
        margin: 0 0 15px;
        flex-basis: 0;

        > div {
            padding: 10px;
            margin-top: 2px;
            background-color: white;
        }

        .club-info {
            display: flex;
        }

        .club-name {
            font-family: $font-bolder;
            color: #5D6064;
        }

        .club-address {
            color: #5D6064;
            font-size: 0.8rem;
            line-height: 1.4rem;
        }

        .distance-container {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            color: #5D6064;
            font-family: $font-bolder;

            .distance {
                font-size: 1.2rem;
            }
        }

        .availability-container {
            display: flex;
            justify-content: space-between;
            color: #5D6064;

            .availability {
                font-family: $font-bolder;

                .next-slot {
                    font-size: 0.85rem;
                }
            }

            .availability.available {
                color: #8348AD;
            }

            .select-button {
                color: white;
                background-color: $primary;
                border-radius: 25px;
                font-family: $font-bolder;
                font-size:13px;
                line-height:1;
                .MuiButton-endIcon {
                  margin-left:0;
                  .MuiSvgIcon-root {
                    font-size:25px !important;
                  }
                }
            }
            .select-button.highlighted {
                background-color: $primary;
            }

            .option-disabled {
                opacity: 0.6;
            }
        }
    }

}
.club-list::-webkit-scrollbar {
    display: none;
}

.search-results {
    margin-top: 7px;
    color: #5D606483;
    font-size: 1rem;
    padding: 5px;
}

@import '../App/Vars.scss';

.club-index {
    .title {
        background-color: $primary;
        height: 8rem;
        color: white;
        display: flex;
        align-items: center;
        font-size: 1.7rem;
        padding-left: 4%;
    }
}

.club-selection-container {
    height: calc(100vh - 8rem - 4em);
    display: flex;
    background-color: #F4F4F4;

    @media (max-width: 600px) {
        height: calc(100vh - 4rem);
    }

    @media (min-width: 960px) {
        .club-list {
            height: calc(100% - 84px);
        }
    }
    .club-list {
        position: relative;
        overflow-y: auto;
    }

    .club-map-container {
        display: flex;

        @media (max-width: 600px) {
            flex-basis: 60%;
        }
    }

    .club-search {
        flex-basis: 0;
    }

    .desktop-list {
        padding: 20px 20px 0;
        position: relative;
    }
}

@media (max-width: 600px) {
    .club-index {
        .title {
            display: none !important;
        }
    }

    .reservations-header.clubs {
        height: 4rem;
    }
}

@media (max-width: 959px) {

    .club-selection-container {
        flex-direction: column;
        
        .club-search {
            padding: 10px 10px 0;
        }
        
        .club-list {
            padding: 0 15px;
        }
    }
}

@import '../App/Vars.scss';

@font-face{
    font-family:'Adelle-Regular';
    src:url("/fonts/2F9DC2_0_0.eot");
    src:url("/fonts/2F9DC2_0_0.eot?#iefix") format("embedded-opentype"),
    url("/fonts/2F9DC2_0_0.woff2") format("woff2"),
    url("/fonts/2F9DC2_0_0.woff") format("woff"),
    url("/fonts/2F9DC2_0_0.ttf") format("truetype")
}
@font-face{
    font-family:'Averta-Bold';
    src:url("/fonts/2F9DC2_3_0.eot");
    src:url("/fonts/2F9DC2_3_0.eot?#iefix") format("embedded-opentype"),
    url("/fonts/2F9DC2_3_0.woff2") format("woff2"),
    url("/fonts/2F9DC2_3_0.woff") format("woff"),
    url("/fonts/2F9DC2_3_0.ttf") format("truetype")
}
@font-face{
    font-family:'Averta-Regular';
    src:url("/fonts/2F9DC2_C_0.eot");
    src:url("/fonts/2F9DC2_C_0.eot?#iefix") format("embedded-opentype"),
    url("/fonts/2F9DC2_C_0.woff2") format("woff2"),
    url("/fonts/2F9DC2_C_0.woff") format("woff"),
    url("/fonts/2F9DC2_C_0.ttf") format("truetype")
}
@font-face {
    font-family: 'f37_moonthin';
    src: url('/fonts/f37moon-thin-webfont.woff2') format('woff2'),
         url('/fonts/f37moon-thin-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'f37_moonregular';
    src: url('/fonts/f37moon-regular-webfont.woff2') format('woff2'),
         url('/fonts/f37moon-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'f37_moonlight';
    src: url('/fonts/f37moon-light-webfont.woff2') format('woff2'),
         url('/fonts/f37moon-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'f37_moonextra_bold';
    src: url('/fonts/f37moon-extrabold-webfont.woff2') format('woff2'),
         url('/fonts/f37moon-extrabold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'f37_moondemi';
    src: url('/fonts/f37moon-demi-webfont.woff2') format('woff2'),
         url('/fonts/f37moon-demi-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'f37_moonblack';
    src: url('/fonts/f37moon-black-webfont.woff2') format('woff2'),
         url('/fonts/f37moon-black-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'f37_moonthin_it';
    src: url('/fonts/f37moon-thinit-webfont.woff2') format('woff2'),
         url('/fonts/f37moon-thinit-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'f37_moonregular_it';
    src: url('/fonts/f37moon-regularit-webfont.woff2') format('woff2'),
         url('/fonts/f37moon-regularit-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'f37_moonlight_it';
    src: url('/fonts/f37moon-lightit-webfont.woff2') format('woff2'),
         url('/fonts/f37moon-lightit-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'f37_moonextra_bold_it';
    src: url('/fonts/f37moon-extraboldit-webfont.woff2') format('woff2'),
         url('/fonts/f37moon-extraboldit-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'f37_moondemi_it';
    src: url('/fonts/f37moon-demiit-webfont.woff2') format('woff2'),
         url('/fonts/f37moon-demiit-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'f37_moonbold_it';
    src: url('/fonts/f37moon-boldit-webfont.woff2') format('woff2'),
         url('/fonts/f37moon-boldit-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'f37_moonblack_it';
    src: url('/fonts/f37moon-blackit-webfont.woff2') format('woff2'),
         url('/fonts/f37moon-blackit-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}

.app {
	height: 100vh;
	background: $gray-light;
    font-family: 'f37_moonregular';
}

// trying to fix the default light gray hover app-wide
.MuiButton-root:hover {
  background: $violet !important;
  color:#fff !important;
}

.mapboxgl-canvas-container {
    height: 100%;
}

.mapboxgl-control-container {
    display: none;
}

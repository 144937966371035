@import '../App/Vars.scss';

.logged-in-user {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    font-family: $font-bolder;
    color: white;

    .user-name {
        font-size: 0.8rem;
        font-family: $font-bolder;
    }

    .user-action {
        font-size: 0.7rem;
        cursor: pointer;
        text-decoration: underline;
    }

}

.fixed-middle-right {
    position: absolute;
    right: 10px;
    top: 0;
    justify-content: center;
    height: 100%;
}
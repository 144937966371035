.maintenance-component {
    position: fixed;
    top: 0;
    bottom: 0;
    height: 100vh;
    width: 100vw;
    background-color: darkslateblue;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .maintenance-icon {
        color: yellow;
    }

    .maintenance-message {
        white-space: pre-line;
    }

}
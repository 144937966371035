.club-map {
    position: relative;
}

.map-container {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
}

.marker {
    background-size: cover;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    cursor: pointer;
}

.current-location-button {
    position: absolute !important;
    background-color: white !important;
    bottom: 30px;
    right: 30px;
    z-index: 1;

    .navigation-icon {
        transform: rotate(45deg);
    }
}

.mapboxgl-canvas-container {
    canvas {
        height: inherit !important;
        width: 100% !important;
    }
}
@import '../App/Vars.scss';

.image-container {
    background: url('../../Images/Reservations/violet-rm.png') $blurple;
    background-color: $blurple;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 20%;
    height: 10rem;

    @media (min-width: 601px) {
        height: 17rem;
    }

    div {
        width: 100%;
        height: 100%;
        font-size: 2.6rem;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-family: $font-bolder;
    
        @media (max-width: 600px) {
            background: rgba(52, 58, 64, 0.45);
            font-size: 1.8rem;
        }
    }
}

.how-it-works {
    text-align: center;
    color: #5D6064;
    background: white;
    padding: 30px 0;

    @media (min-width: 601px) {
        margin-top: -20px !important;
        padding: 30px 7%;
        height: auto;
    }

    .how-title {
        font-size: 2rem;
        font-family: 'Averta-bold';
    }

    .button-container {
        margin: 25px auto 30px;
    }

    button {
        border-radius: 40px;
        padding: 12px 0;
        width: 100%;
        text-align: center;
        font-family: $font-bold;
        font-size: 1rem;
    }

    .manage-button {
        border: solid 2px $primary;
        color: $primary;
    }

    .start-button {
        border: solid 2px $primary;
        background: $primary;
        color: white;

        @media (max-width: 600px) {
            border: solid $primary;
            background-color: $primary;
        }
    }
}
@import '../App/Vars.scss';

.res-header {
    background: $purple-deep;
    height: 2em;
    padding: 1em;
    position: relative;
    img {
        height: 2em;
        margin: 0 auto;
    }

    @media (max-width: 600px) {
        text-align: start;
        .header-image-desktop {
            display: none;
        }
    }

    @media (min-width: 601px) {
        .header-image-mobile {
            display: none;
        }
    }
}
.res-subheader{
    display: none;
    background: $primary;
    padding: 0.75em 0.75em 0.75em 5%;
    position: relative;
    font-size: 1.2em;
    color: #fff;
    z-index: 1;

    h1{
        position: relative;
        z-index: 1;
    }
}
.res-gyminfo{
    padding: 1em 2em;

    h1{
        text-transform: uppercase;
        font-family: $font-bolder;
        font-size: 1.5em;
        color: $violet;
    }
    h2{
        margin-top: 0.5em;
        font-size: 1.2em;
    }
}
.res-address{
    font-size: 0.8em;
    margin-bottom: 1em;
}
.res-guest {
  font-size: 0.8em;
  margin-bottom: 1em;
}
.res-return{
    font-size: 0.8em;
    color: $primary;
    border-bottom: 1px solid $primary;
    text-decoration: none;
}

.res-day{
    h3{
        background: $aqua-light;
        padding: 1em 2em;
        font-family: $font-bolder;
    }
}
.res-day-times {
   max-height: calc(35vh - 52px);
   overflow: auto;
}
.res-day-empty{
    text-align: center;
    color: #ccc;
    padding: 1em;
}
.res-day-reserved{
    color: #86A62A;
    float: right;
}
.res-timeslot{
    display:flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin: 1em 2em;
    height: 2.5em;

    a {
        transition: 0.5s all ease-in-out;
        display: block;
    }
}
.res-timeslot-select{
    width: 100%;

    border: 0.1em solid $primary;
    border-radius: 3em;
    position: relative;
    text-align: center;
    text-decoration: none;
    padding: 0.5em;
    height: 2.5em;
    box-sizing: border-box;
    color: $primary;
    font-family: $font-bolder;
}
.res-timeslot-label{
    opacity: 1;

    position: absolute;
    left: 0.8em;
    top: 0.6em;
    padding: 0.5em 1em;
    line-height: 1;
    border-radius: 3em;
    background: $primary;
    font-size: 0.7em;
    text-transform: uppercase;
    color: #fff;
}
.res-timeslot-confirm {
    width: 0;
    opacity: 0;

    text-align: center;
    text-decoration: none;
    padding: 0.5em 0;
    font-family: $font-bolder;
    box-sizing: border-box;
    background-color: $primary;
    border-radius: 3em;
    color: #FFF;
}
.res-timeslot-active {
    .res-timeslot-select {
        width: 47%;
        margin-right: 3%;
        opacity: 1;
        color: $dark;
        background: $aqua;
        border-color: $aqua;
    }
    .res-timeslot-confirm {
        width: 50%;
        opacity: 1;
    }
    .res-timeslot-label{
        opacity: 0;
    }
}
.res-timeslot-reserved{
    border-color: #86A62A;
    color: #86A62A;
    .res-timeslot-select {
        border-color: #86A62A;
        color: #86A62A;
    }
}
.res-login{
    color: #fff;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1em 2em;
    text-align: center;
    background: $primary;
}
.res-loginbutton{
    border-radius: 3em;
    background: #fff;
    color: $primary;
    text-transform: uppercase;
    text-decoration: none;
    font-family: $font-bolder;
    padding: 0.5em 1em;
    margin-top: 1em;
    display: block;
}
.res-confirming{
    background: #4C346B;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.res-final{
    // extra padding to accomodate fixed bottom button
    padding-bottom:80px;
    .res-header {
        display: block;
    }
    height: 100vh;
    background: #eee;
}
.res-final-success{
    text-align: center;
    .triangle {
      clip-path: polygon(50% 100%, 0 0, 100% 0);
      background: $aqua;
      display: block;
      width: 100%;
      height: 40px;
    }
}
.res-final-confirmed{
    padding: 0.75em 0.5em 0 0.5em;
    background: $aqua;

    font-family: $font-bolder;
    font-size: 2em;
    text-transform: uppercase;
}
.res-final-error{
    background: #ddd;
    font-size: 1.2em;
    font-family: $font-bolder;
    padding: 1em 2em;
    text-align: center;
}
.res-final-message{
    margin: 1em auto;
    width: 20em;
    text-align: center;

    b{
        font-family: $font-bolder;
    }
    h3{
        text-align: left;
        font-size: 1.2em;
    }
    ul{
        margin: 1em 0 0 1em;
        text-align: left;
    }
    li{
        margin: 1em 0;
    }
    a{
        color: $primary;
    }
}
.res-final-datetimebox{
    background: #fff;
    width: 20em;
    margin: 0.1em auto;
    padding: 1em;
    text-align: center;

    p{
        font-family: $font-bolder;
        font-size: 1.5em;
    }
}
.res-final-selectnew{
    border-radius: 3em;
    background: $primary;
    color: #fff;
    text-transform: uppercase;
    text-decoration: none;
    padding: 0.6em 0;
    font-size:110%;
    font-weight:600;
    margin: 1em 5%;
    box-shadow: 0 5px 20px 20px #eee;
    width: 90%;
    display: block;
    box-sizing: border-box;
    text-align: center;
    position: fixed;
    bottom: 0;
    left: 0;
}
.res-club-name{
    font-size: 1.2em;
    text-decoration: none;
    color: #5D6064;
}
.res-final-datetimebox{
    a{
        text-decoration: none;
    }
    .res-address{
        color: $primary;
        display: block;
        margin-bottom: 0;
    }
}

.res-newtime{
    border-radius: 3em;
    background: $primary;
    color: #fff;
    text-transform: uppercase;
    text-decoration: none;
    padding: 0.5em 1em;
    margin: 1em;
    display: block;
    box-sizing: border-box;
    text-align: center;
}
.res-existing{
    padding: 1em 2em;
    border-top: 1px solid #aaa;
}
:last-child.res-existing{
    border-bottom: 1px solid #aaa;
}
.res-existing-cancel{

    border: 0.15em solid #444;
    color: #444;
    border-radius: 3em;

    font-family: $font-bolder;
    font-size: 0.8em;
    line-height: 1;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;

    float: right;
    margin-top: 0.5em;
    padding: 0.5em 1em 0.3em 1em;
}
.res-existing-name{
    color: $primary;
    text-decoration: none;
}




@media (orientation: landscape) and (min-width: 800px) {
    .res-header,
    .res-subheader{
        display: block;
    }

    .res-header {
        img {
            cursor: pointer;
        }
    }

    .res-gyminfo{
        top: 0;
        height: 100vh;
        padding: 7.33em 0 0 0;
        left: 5vw;
        width: 28vw;
        position: fixed;
        box-sizing: border-box;

        h1{
            color: $violet;
            margin: 2em 0 1em 0;
        }
        h2{
            border-top: 2px solid #EAEAEA;
            padding-top: 1.5em;
        }
        .res-address{
            border-bottom: 2px solid #EAEAEA;
            padding-bottom: 2em;
        }
    }
    .res-container{
        top: 6.5em;
        height: calc(100vh - 6.5em);
        box-sizing: border-box;
        left: 35vw;
        width: 60vw;
        position: fixed;
        z-index: 2;
    }
    .res-content{
        height: 100%;
        box-sizing: border-box;
        overflow-x: hidden;
        overflow-y: auto;
        background: #fff;
        box-shadow: 0 2px 5px rgba(0,0,0,0.2);
    }
    .res-login{
        position: absolute;
    }
}

@import '../App/Vars.scss';

.club-search {
    height: 84px;
}

.club-search.no-reservations {
    height: auto;
}

.no-reservations .existing-reservations {
    display: none;
}

.existing-reservations {
    background-color: #D7EDAB;

    color: #000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 7px 14px;
    height: 2.2rem;

    .reservations-qty {
        font-family: 'Averta-bold';
    }

    .modify {
        font-family: 'Averta-bold';
        color: #637F1D;
    }
}

    @media (min-width: 960px) {
        .search-component {
            margin-top: 2px !important;
        }
    }

.mapboxgl-ctrl-geocoder {
    background-color: white;
    padding: 8px 14px;
    display: flex;
    align-items: center;
    box-shadow: 0 3px 6px #00000029;
    max-width: 100%;
    width: 100%;
    margin: 0;
    border-radius: 0;

    input {
        border: none;
        outline: none;
        font-family: $font-bolder;
        color: #5D6064;
        font-size: 1rem;
        display: flex;
        flex-grow: 1;
        width: 100%;
        height: auto;
        padding: 0 35px 0 0;
    }
    .geocoder-icon-close {
        margin-right: 20px;
        display: none;
        border: none;
        font-weight: bold;
        background: transparent;
        cursor: pointer;
        font-family: 'averta-regular';
        font-size: 1.1rem;
        height: 22px;
        width: 20px;
        text-align: center;
        line-height: 20px;
        font-size: 20px;
        outline: none;
    }
    .mapboxgl-ctrl-geocoder--icon-search {
        display: none;
    }
    .mapboxgl-ctrl-geocoder--pin-right {
        >button {
            outline: none;
        }
        > button:hover {
            background: transparent;
        }
    }
}
.mapboxgl-ctrl-geocoder--icon-close {
    margin-right: 25px;

}

.input-container {
    background-color: white;
    padding: 8px 14px;
    display: flex;
    align-items: center;
    box-shadow: 0 3px 6px #00000029;
    .search-input {
        width: calc(100% - 2rem);
    }

    input::placeholder {
        font-family: 'Averta-bold';
        color: #5D6064;
        opacity: 1;
    }


}

.search-component {
    position: relative;
}
.search-icon {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 5;
    bottom: 0;
    height: 100%;
    right: 10px;
    cursor: pointer;
    font-size: 1.8rem;
    color: #79519a;
    height: 100% !important;
}

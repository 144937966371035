.language-picker {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 1000;
    background: #4C346B;
    padding-left: 5px;

    .picker-select {
        color: white;
        font-family: 'Averta-bold';
    }
}

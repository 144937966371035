@import '../App/Vars.scss';

.reservations-header {
    background-color: $purple-deep;
    height: 5em;
    padding: 1rem;
    align-items: center;
    position: relative;

    img {
        height: 2em;
        cursor: pointer;
    }

    @media (min-width: 601px) {
        .image-mobile {
            display: none;
        }
    }
    @media (max-width: 600px) {
        .image-desktop {
            display: none;
        }
    }
}

@media (max-width: 600px) {
    .reservations-header {
        justify-content: flex-start;
        padding-left: 1em;
    }
}

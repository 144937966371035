@import '../App/Vars.scss';

.admin{
    color: #5D6064;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}
.admin-header {
    background: $purple-deep;
    height: 2em;
    padding: 1em;

    img {
        cursor: pointer;
        height: 2em;
        margin: 0 auto;
    }
}
.admin-subheader{
    background: $primary;
    padding: 0.75em 0.75em 0.75em 5%;
    position: relative;
    font-size: 1.2em;
    color: #fff;
    display: flex;
    justify-content: space-between;

    h1{
        position: relative;
        z-index: 1;
    }
}
.admin-returnto{
    color: rgba(255,255,255,0.5);
    margin-right: 2em;
    text-decoration: none;
    font-size: 0.8em;
    z-index: 1;
}
.admin-page{
    position: relative;
}
.admin-sidebar{
    top: 0;
    height: 100vh;
    padding-top: 7.33em;
    left: 5vw;
    width: 28vw;
    position: fixed;
    box-sizing: border-box;

    h2{
        font-family: $font-bolder;
        font-size: 1.5em;
        margin: 1.5em 0;
    }
}
.admin-sidebar-content {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    padding-right: 1em;
}
.admin-sidebar-section{
    border-top: 2px solid #EAEAEA;
    padding: 2em 0;

    h3{
        font-family: $font-bolder;
        font-size: 1.2em;
    }
}
.admin-sidebar-month{
    h3{
        margin: 1em 0 0.5em 0;
    }
}
.admin-container{
    top: 6.5em;
    height: calc(100vh - 6.5em);
    box-sizing: border-box;
    left: 35vw;
    width: 60vw;
    position: fixed;
    z-index: 2;
}
.admin-content-header{
    overflow-y: scroll;
    position: fixed;
    width: 60%;
    height: 3em;
    background: #fff;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
}
.admin-content{
    height: 100%;
    box-sizing: border-box;
    padding-top: 3em;
    overflow-x: hidden;
    overflow-y: scroll;
    background: #fff;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
}
.admin-content-tr{
    padding: 0.5em 0;
    overflow: hidden;
}
.admin-th{
    float: left;
    height: 2em;
    line-height: 2;
    padding: 0.5em 0 0.5em 2%;
}
.admin-td{
    float: left;
    min-height: 1em;
    line-height: 1;
    padding: 0.5em 0 0.5em 2%;
}
.admin-td-10{
    width: 8%;
}
.admin-td-15{
    width: 13%;
}
.admin-td-20{
    width: 18%;
}
.admin-td-30{
    width: 28%;
}
.admin-td-40{
    width: 38%;
}
.admin-td-45{
    width: 43%;
}
.admin-td-50{
    width: 48%;
}
.admin-content-loading{
    padding: 1em;
    color: #888;
    background: #ddd;
    text-align: center;
}
.admin-status-open{
    line-height: 0.25em;
    vertical-align: sub;
    font-size: 2em;
    color: green;
}
.admin-status-closed{
    line-height: 0.25em;
    vertical-align: sub;
    font-size: 2em;
    color: red;
}
.admin-arrow{
    border-radius: 3em;
    background: $primary;
    text-decoration: none;
    color: #fff;
    padding: 0.3em 1em;
}
.admin-search{
    border: 1px solid $primary;
    border-radius: 10em;
    overflow: hidden;
    position: relative;

    label{
        padding: 1em 0 0.1em 2.5em;
        display: block;
        color: #888;
        background-color: #FFF;

        span{
            font-size: 0.9em;
        }
    }
}
.admin-search-text{
    padding: 0.1em 0 1em 2.5em;
    border: 0;
    font-size: 1em;
    outline: 0;
    width: 100%;
    box-sizing: border-box;
}
.admin-search-button{
    background: $primary;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 4em;
    height: 100%;

    img{
        width: 1.5em;
        height: 1.5em;
        color: #fff;
        display: block;
        margin: 1.5em auto 0;
    }
}

.admin-club-address{
    margin-top: 0.5em;
    font-size: 0.5em;
    color: #888;
}
.admin-disabled-message{
    padding: 0 2em;
    text-align: center;
}
.admin-defaults-button{
    border: 0.2em solid $primary;
    float: right;
    color: $primary;
    font-size: 0.7em;
    font-family: $font-bolder;
    text-transform: uppercase;
    border-radius: 3em;
    padding: 0.3em 1em;
    text-decoration: none;
}
.admin-defaults-info{
    margin: 1em 0;
    font-size: 0.8em;
}
.admin-defaults-line{
    margin: 0.5em 0;
    b{
        font-family: $font-bolder;
    }
}
.admin-managetimes-button{
    margin-top: 1em;
    display: block;
    background: $primary;
    color: #fff;
    font-size: 0.8em;
    text-transform: uppercase;
    border-radius: 3em;
    padding: 0.75em 1em;
    text-decoration: none;
    text-align: center;
}
.react-calendar{
    border: 0 !important;

    .day-with-reservations {
        font-family: $font-bolder;
        color: $primary;
    }
    .react-calendar__tile--now{
        background: #fff;
    }
    .react-calendar__month-view__days__day--weekend{
        color: #5D6064;
    }
    .react-calendar__tile:enabled:hover,
    .react-calendar__tile:enabled:focus{
        background: #eee;
    }
    .react-calendar__tile--active,
    .react-calendar__tile--active:enabled:hover,
    .react-calendar__tile--active:enabled:focus{
        background: #fff;
        border: 0.1em solid $primary;
        border-radius: 0.5em;
        color: #8348AD;
        font-family: $font-bolder;
        padding: 0.65em 0.5em;
    }
    .react-calendar__month-view__days__day--neighboringMonth{
        color: #ccc;
    }

    button{
        box-sizing: border-box;
        color: #5D6064;
        font-size: 1em;
    }
}
.go-back{
    margin-top: -4px;
    margin-left: -15px;
    height: 27px;
    cursor: pointer;
    border: 0.2em solid $primary;
    float: left;
    color: $primary;
    font-size: 0.7em;
    font-family: $font-bolder;
    text-transform: uppercase;
    border-radius: 3em;
    // padding: 0.3em 1em;
    text-decoration: none;
}
.react-calendar__navigation__label{
    font-family: $font-bolder;
    font-size: 1em;
}
.admin-content-header-day{
    font-family: $font-bolder;
    float: left;
    height: 2em;
    line-height: 2;
    padding: 0.5em 0 0.5em 2%;
}
.admin-content-header-available{
    color: #888;
    float: right;
    height: 2em;
    line-height: 2;
    padding: 0.5em 2% 0.5em 0;
}
.admin-timeslot{
    border: 1px solid #5D6064;
    border-radius: 0.75em;
    margin: 1em;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}
.admin-timeslot-section{
    display: flex;
}
.admin-timeslot-time{
    width: 8em;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0.5em;

    p{
        font-size: 1.5em;
    }
    b{
        font-weight: normal;
        display: block;
    }
}
.admin-timeslot-senior{
    font-size: 0.4em;
    background: #5D6064;
    text-transform: uppercase;
    color: #fff;
    padding: 0.3em 0.5em;
    display: inline-block;
}
.admin-reservations{
    flex: 1;
}
.admin-reservations-type{
    display: flex;
}
.admin-reservations-count{
    width: 5em;
    padding: 0.5em;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: #eee;
    b{
        font-size: 1.2em;
    }
    i{
        font-size: 0.8em;
        font-style: normal;
        display: block;
    }
    border-top: 1px solid #ccc;
}
.admin-reservations-list{
    border-top: 1px solid #ccc;
}
.admin-reservations-row{
    padding: 0.5em;
    border-top: 1px solid #ccc;
    display: flex;
}
:first-child.admin-reservations-type .admin-reservations-count,
:first-child.admin-reservations-type .admin-reservations-list,
:first-child.admin-reservations-row,
.admin-timeslot-cleaning .admin-reservations-count,
.admin-timeslot-cleaning .admin-reservations-list{
    border-top: 0;
}
.admin-reservations-name{
    flex: 2;
}
.admin-reservations-ident{
    flex: 2;
    color: #aaa;
}
.admin-reservations-cancel{
    width: 2em;
    img{
        width: 1em;
        height: 1em;
    }
}
.admin-reservations-list{
    flex: 1;
}
.admin-timeslot-cleaning{
    border-top: 1px solid #ccc;
    background: #ecf7ea;
    .admin-timeslot-time p{
        font-size: 1em;
    }
    .admin-reservations-count{
        background: #ecf7ea;
    }
}
.admin-reservations-add{
    display: inline-block;
    background: $primary;
    color: #fff;
    font-size: 0.7em;
    text-transform: uppercase;
    border-radius: 3em;
    padding: 0.5em 1em;
    text-decoration: none;
    text-align: center;
}

.admin-times-day-row{
    border: 0.2em solid #fff;
    border-radius: 0.5em;
    display: flex;
    margin: 0.5em 0;
    text-decoration: none;
    color: #5D6064;
}
.admin-times-date{
    width: 3em;
    padding: 0.5em;
    background: #eee;
    border-top-left-radius: 0.5em;
    border-bottom-left-radius: 0.5em;
    text-align: center;

    i{
        display: block;
        font-size: 0.7em;
    }
    b{
        display: block;
        font-size: 1.1em;
    }
}
.admin-times-rescount{
    flex: 1;
    padding: 1em;
    background: #eee;
    border-top-right-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
    margin-left: 0.25em;
}
.admin-times-rescount-none{
    color: #aaa;
}
.admin-times-day-row-selected{
    border: 0.2em solid $primary;
    border-radius: 0.5em;

    .admin-times-date{
        background: none;
        border-radius: 0;
    }
    .admin-times-rescount{
        background: none;
        border-radius: 0;
        font-family: $font-bolder;
    }
}



.admin-times-header{
    margin: 0 1em;
    padding: 1em 1.1em;
}
.admin-times-td{
    width: 12%;
    float: left;
    text-align: center;
}
:first-child.admin-times-td{
    text-align: left;
}
.admin-times-td-large{
    width: 26%;
}
.admin-times-row{
    border: 0.1em solid #5D6064;
    border-radius: 0.75em;
    margin: 1em;
    padding: 1em;
    overflow: hidden;
}
.admin-times-row :first-child.admin-td{
    padding-left: 0;
}
.admin-times-edit{
    display: inline-block;
    background: $primary;
    color: #fff;
    font-size: 0.8em;
    text-transform: uppercase;
    border-radius: 3em;
    padding: 0.75em 1.5em;
    text-decoration: none;
    text-align: center;
}
.admin-times-add{
    display: block;
    background: $primary;
    color: #fff;
    font-size: 0.8em;
    text-transform: uppercase;
    border-radius: 3em;
    padding: 0.75em 1.5em;
    margin: 0 1em;
    text-decoration: none;
    text-align: center;
    margin-bottom: 15px;
}

.blanket{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.7);
    z-index: 11;
}
.modal{
    position: fixed;
    top: 10vh;
    left: 5vw;
    right: 5vw;
    background: #fff;
    border-radius: 0.3em;
    box-shadow: 2px 2px 5px rgba(0,0,0,0.5);
    z-index: 12;

    .modal-header{
        overflow: hidden;
        padding: 1em 2em;
        border-bottom: 0.1em solid #ddd;

        h3{
            font-family: $font-bolder;
            font-size: 1.2em;
        }
    }

    .modal-footer{
        display: flex;
        justify-content: center;
        padding: 30px
    }

    .modal-done{
        float: right;
        background: $primary;
        color: #fff;
        font-size: 0.7em;
        text-transform: uppercase;
        border-radius: 3em;
        padding: 0.5em 1.5em;
        text-decoration: none;
        text-align: center;
    }

    .modal-done.big {
        padding: 0.8em;
        margin: 0.2em;
        font-size: 1em;
        min-width: 4em;
    }
    .modal-done.big.cancel {
        background-color: #FFFFFF;
        border: solid 1px $primary;
        color: $primary;
    }


}


.choose-members{
    margin: 1em 0 !important;
    gap: 0.75em !important;

    .choose-member{
        width: calc(33% - 2em);
        max-width: calc(33% - 2em);
        border: 1px solid $primary;
        border-radius: 15px;
        text-align: center;
        padding: 1em;
        margin: 0;

        .member-name{
            font-size: 135%;
        }
        .member-email{
            margin: 0.25em;
            font-size: 75%;
        }
    }

}

.modal-close{
    float: right;
    color: #5D6064;
    text-decoration: none;
    font-family: $font-bolder;
}
.modal-form{
    padding: 0 2em;
    overflow-y: auto;
    max-height: 75vh;

    .member-email{
        width: 80%;
    }

    .member-last-name{
        width: 60%;
    }
}
.modal-field{
    padding: 1em 0;
    border-bottom: 1px solid #ddd;
    display: flex;
}
:last-child.modal-field{
    border: 0;
}
.modal-field-label{
    flex: 1;

    h4{
        margin-bottom: 0.25em;
    }

    p{
        font-size: 0.8em;
        color: #888;
    }
}

.modal-field-input{
    select{
        border: 1px solid $primary;
        border-radius: 3em;
        background: #fff;
        padding: 0.3em 1em;
        font-size: 1.1em;
    }
    .modal-field-senior-label{
        float: right;
    }
    .modal-senior-fields{
        padding-top: 0.5em;
        display: block;
        clear: both;
    }
    table {
        width: 100%;
        th {
            font-weight: bold;
            text-decoration: underline;
            color: black;
            font-size: 14px;
        }
        td {
            padding: 5px;
            text-align: center;
            vertical-align: middle;
        }
        td > * {
            margin: 0 auto;
        }
    }
}

.modal-field-input-to{
    margin: 0 0.5em;
}

.modal-hours{
    border-radius: 0.25em;
    background: #eee;
    padding: 0.3em;
    margin-bottom: 0.5em;
    display: flex;

    .modal-hours-days{
        display: flex;

        a{
            border: 0.1em solid $primary;
            border-radius: 0.25em;
            background: $primary;
            color: #fff;
            padding: 0.4em 0.3em;
            text-transform: uppercase;
            text-decoration: none;
            text-align: center;
            font-size: 0.7em;
            font-family: $font-bolder;
            margin-left: 0.25em;
            width: 3em;
            height: 1em;
            line-height: 1;
        }
        a:first-child{
            margin-left: 0;
        }
        a.unchecked{
            opacity: 0.5;
            background: #fff;
            color: $primary;
        }
    }
    .modal-hours-times{
        display: flex;
        margin-left: 1em;
        padding-right: 20px;
    }
    .modal-hours-delete{
        display: block;
        width: 1.1em;
        height: 1.1em;
        margin: 0.1em 0 0 1em;

        img{
            width: 100%;
            height: 100%;
        }
    }
}
.modal-hours-add{
    text-align: right;
    a{
        border: 0.1em solid $primary;
        border-radius: 3em;
        background: #fff;
        color: $primary;
        padding: 0.3em 1em;
        text-decoration: none;
        text-transform: uppercase;
        font-size: 0.8em;
    }
}

.modal-small-field{
    margin: 1em auto;
    width: 90%;

    &.flex{
        display: flex;
        gap: 1em;
        justify-content: start;
        align-items: center;
    }

    &.flex-centered{
        display: flex;
        gap: 1em;
        justify-content: center;
        align-items: center;
    }

    label{
        display: block;
        padding-bottom: 0.3em;
        text-align: center;
    }

    input.text{
        display: block;
        text-align: center;
        width: 100%;
        border: 0.1em solid $primary;
        border-radius: 3em;
        background: #fff;
        padding: 0.3em 1em;
        font-size: 1.2em;
        box-sizing: border-box;
        &:disabled{
            background: #ccc;
        }
        &:invalid{
            border-color: red;
        }
    }

    .modal-submit{
        cursor: pointer;
        display: block;
        background: $primary;
        color: #fff;
        border: 0;
        border-radius: 3em;
        padding: .75em 2.5em;
        text-decoration: none;
        text-align: center;
        font-weight: bold;

        &:disabled{
            opacity: .4;
        }

        img{
            width: 0.8em;
            height: 0.8em;
            color: #fff;
            display: inline;
            vertical-align: baseline;
            margin-right: 0.5em;
            margin-left: -0.5em;
        }
    }
    .modal-submit-secondary{
        background: #888;
    }

    .dot-step{
        div{
            color: $primary;
            &:first-child{
                background-color: $primary;
                color: #fff;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: .4em;
                padding: .2em;
                border-radius: 50px;
                width: 30px;
                font-size: 29px;
                font-weight: 900;
                &.valid{
                    background-color: #59c4b1
                }
            }
        }
    }
}



.modal-working{
    margin: 2em auto;
    text-align: center;
    font-size: 1.5em;
    color: #aaa;
}
.modal-message{
    margin: 1em auto;
    width: 90%;
    text-align: center;
}
.modal-longmessage{
    margin: 1em auto;
    width: auto;
    text-align: center;
    white-space: pre-line;
}
.modal-member-info{
    margin: 1em auto;
    text-align: left;
    width: 90%;
    font-size: 1.5em;
    border-top: 1px solid #ddd;
    padding-top: 1em;

    b{
        font-family: $font-bolder;
    }
}

@media (orientation: landscape) and (min-width: 800px) {
    .modal-small{
        left: 30vw;
        right: 30vw;
    }
}

.admin-in-modal {
    margin-top: 6.5em;
}
